import React from "react";
import { Dropzone } from "../common/Dropzone/dropzone";
import { Dropdown, InputField } from "../common/InputField/inputField";
import { FormProps, MarketData } from "../common/Interface/types";
import { Floppy } from "react-bootstrap-icons";

export const Form: React.FC<FormProps> = ({
  formData,
  selectedMarket,
  marketData,
  errors,
  handleChange,
  handleMarketSelect,
  setFileNames,
  setFormData,
  fileNames,
  handleSubmit,
}) => {
  const getMarketLabel = (market: MarketData) => market.name;
  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="add-market-content">
          <div className="bg-dark-card my-3 my-md-4 border-bottom-custome">
            <div className="add-market-card">
              <Dropzone
                setFileNames={setFileNames}
                setFormData={setFormData}
                fileNames={fileNames}
              />
              <Dropdown
                label="Choose Market"
                data={marketData}
                selectedItem={selectedMarket}
                handleSelect={handleMarketSelect}
                getItemLabel={getMarketLabel}
                error={errors.market_id}
                placeholder="Select a Market"
                message="No market available"
              />
              <InputField
                id="assetName"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                label="Asset Name"
                error={errors.name}
              />
              <InputField
                id="tickerName"
                type="text"
                name="ticker_name"
                value={formData.ticker_name}
                onChange={handleChange}
                label="Ticker Name"
                error={errors.ticker_name}
              />
            </div>
          </div>
          <div className="btn-bar">
            <button
              type="submit"
              className="btn btn-gray me-auto d-flex"
              data-bs-dismiss="modal"
            >
              <Floppy className="me-2" />
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
