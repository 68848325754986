import Currencies from "../common/CurrencyList/currencies.json";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { Pencil, PlusSquare, Trash } from "react-bootstrap-icons";
import { ITimezone } from "react-timezone-select";
import { AppContext } from "../../App";
import { Header, Modal, Sidebar } from "../common";
import {
  MarketData,
  MarketsResponse,
  validateMarketForm,
  ValidationErrors,
} from "../common/Interface/types";
import Toast from "../common/Toasts";
import { getData, postData, putData } from "../utils/api";
import { Form } from "./form";

const MarketList = () => {
  const { token, setAdmin } = useContext(AppContext);
  const [formData, setFormData] = useState<MarketData>({
    name: "",
    currency_code: "",
    currency_symbol: "",
    time_zone: "",
  });
  const [marketData, setMarketData] = useState<MarketData[]>([]);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [showErrors, setShowErrors] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentMarket, setCurrentMarket] = useState<MarketData | null>(null);
  const [selectedTimeZone, setSelectedTimeZone] = useState<ITimezone | null>(
    Intl.DateTimeFormat().resolvedOptions().timeZone || null,
  );
  const inr = Currencies.INR;
  const [selectedCurrency, setSelectedCurrency] = useState<any>(
    inr.symbolNative || null,
  );
  const [toastMessage, setToastMessage] = useState<{
    text: string;
    type: "success" | "error";
  } | null>(null);

  useEffect(() => {
    if (!localStorage.getItem("authToken")) setAdmin(null);
  }, [setAdmin]);

  const handleMarketApi = async () => {
    if (token) {
      try {
        const response: MarketsResponse = await getData(
          "/admin/markets",
          token,
        );
        setMarketData(response.markets.data || []);
      } catch (error: any) {
        setToastMessage({
          text: error?.data || error?.message,
          type: "error",
        });
      }
    }
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement> | any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    showErrors && setErrors({});
  };
  const handleEditClick = (id: any) => {
    const marketObj = marketData.find((data) => data.id === id);
    if (marketObj) {
      setCurrentMarket(marketObj);
      setFormData({
        name: marketObj?.name,
        currency_code: marketObj?.currency_code || "",
        time_zone: marketObj?.time_zone || "",
        currency_symbol: marketObj?.currency_symbol || "",
      });
      const timezoneObject: any = {
        label: marketObj.time_zone,
      };
      const currencyObject: any = {
        label: marketObj.currency_code,
      };
      setSelectedTimeZone(timezoneObject);
      setSelectedCurrency(currencyObject);
      setIsModalVisible(true);
      setIsModalVisible(true);
    }
  };

  const currencyData = Object.entries(Currencies).map(
    ([code, details]) => ({
      value: code,
      label: `${details.name} (${code}) - ${details.symbolNative}`,
      symbol: `${details.symbolNative}`,
    }),
  );

  const handleCurrencySelect = (currency: {
    label: string;
    symbol: string;
  }) => {
    setSelectedCurrency(currency);
    setFormData((prev) => ({
      ...prev,
      currency_code: currency.label,
      currency_symbol: currency.symbol,
    }));
  };

  const handleTimeZoneSelect = (timezone: { label: string } | any) => {
    setSelectedTimeZone(timezone);
    handleChange({ target: { name: "time_zone", value: timezone.label } });
  };

  const getCurrencyLabel = (currency: { label: string }) => currency.label;
  const resetForm = () => {
    setFormData({
      name: "",
      currency_code: "",
      time_zone: "",
      currency_symbol: "",
    });
    setErrors({});
    setShowErrors(false);
    setIsModalVisible(false);
    setCurrentMarket(null);
    setSelectedTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    setSelectedCurrency(inr.symbolNative);
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validateMarketForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setShowErrors(true);
      return;
    }
    try {
      if (currentMarket) {
        await putData(`/admin/markets/${currentMarket.id}`, token, formData);
        setToastMessage({
          text: "Market name updated succesfully",
          type: "success",
        });
      } else {
        await postData("/admin/markets", token, formData);
        setToastMessage({
          text: "Market name added succesfully",
          type: "success",
        });
      }
      handleMarketApi();
      resetForm();
    } catch (error: any) {
      setToastMessage({
        text: error?.data || error?.message,
        type: "error",
      });
    }
  };
  const handleClose = () => {
    resetForm();
  };
  const handleToastClose = () => {
    setToastMessage(null);
  };
  useEffect(() => {
    handleMarketApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      {toastMessage && (
        <Toast
          message={toastMessage}
          onClose={handleToastClose}
          setMessage={setToastMessage}
        />
      )}
      <main className="main">
        <Sidebar />
        <div className="app-main">
          <div className="app-content">
            <div className="app-market-content flex-column">
              <div className="qt-market-header w-100">
                <h2 className="mb-0">Markets</h2>
              </div>
              <div className="w-100 add-market-section">
                {marketData.length > 0 ? (
                  <div className="row g-3">
                    <div className="col-lg-3 col-sm-6 d-flex">
                      <div className="bg-dark-card w-100 d-flex">
                        <div className="qt-card qt-add bg-black">
                          <div className="qt-card-top d-flex justify-content-center flex-column bg-transparent">
                            <PlusSquare className="icon-xxl m-auto" />
                            <p className="mb-0">Add New Market</p>
                          </div>
                          {/* eslint-disable-next-line */}
                          <a
                            href="#"
                            className="stretched-link"
                            onClick={() => setIsModalVisible(true)}
                          ></a>
                        </div>
                      </div>
                      {isModalVisible && (
                        <Modal
                          title={currentMarket ? "Update Market" : "Add Market"}
                          onClose={handleClose}
                        >
                          <Form
                            handleSubmit={handleSubmit}
                            formData={formData}
                            handleChange={handleChange}
                            dismissValue="modal"
                            error={errors}
                            handleTimeZoneSelect={handleTimeZoneSelect}
                            selectedTimeZone={selectedTimeZone}
                            currencyData={currencyData}
                            handleCurrencySelect={handleCurrencySelect}
                            getCurrencyLabel={getCurrencyLabel}
                            selectedCurrency={selectedCurrency}
                          />
                        </Modal>
                      )}
                    </div>
                    {marketData.map((data) => (
                      <div className="col-lg-3 col-sm-6 d-flex" key={data.id}>
                        <div className="bg-dark-card w-100 border-custome-bottom">
                          <div className="qt-card bg-card-img">
                            <div className="qt-card-top">
                              <i className="i-market-up icon-lg m-auto d-block"></i>
                              <p className="mb-0">
                                {data.name}{" "}
                                {data?.currency_symbol &&
                                  `(${data?.currency_symbol})`}
                              </p>
                              <p>{data?.time_zone}</p>
                            </div>
                            <div className="action-bar">
                              <button
                                className="btn bg-gray d-flex align-items-center justify-content-center"
                                onClick={() => handleEditClick(data?.id)}
                              >
                                <Pencil />
                              </button>
                              <button className="btn bg-gray d-flex align-items-center justify-content-center">
                                <Trash />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="modal-content">
                    <Form
                      handleSubmit={handleSubmit}
                      formData={formData}
                      handleChange={handleChange}
                      error={errors}
                      handleTimeZoneSelect={handleTimeZoneSelect}
                      selectedTimeZone={selectedTimeZone}
                      currencyData={currencyData}
                      handleCurrencySelect={handleCurrencySelect}
                      getCurrencyLabel={getCurrencyLabel}
                      selectedCurrency={selectedCurrency}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default React.memo(MarketList);
