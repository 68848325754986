import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface ToastProps {
  message: {
    text: string;
    status?: number;
    type?: "success" | "error";
  } | null;
  onClose: () => void;
  setMessage: any;
}

const Toast: React.FC<ToastProps> = ({ message, onClose, setMessage }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (message?.status === 401) {
      localStorage.removeItem("authToken");
      navigate("/", {
        state: {
          message: "You are unauthorized to perform this action",
          success: false,
        },
      });
    } else {
      setMessage(message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (message) {
      timer = setTimeout(() => {
        setMessage(null);
      }, 3000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  if (!message) return null;

  return (
    <div
      className="toast"
      style={{
        padding: "10px 15px",
        maxWidth: "400px",
        width: "auto",
        color: message.type === "error" ? "#d32f2f" : "#155724",
        backgroundColor: message.type === "error" ? "#ffaaaa" : "#d4edda",
        fontSize: "1rem",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "fixed",
        top: "6rem",
        right: "4rem",
        zIndex: 9999,
        pointerEvents: "auto",
        borderRadius: "4px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
      }}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className="toast-body" style={{ flex: 1 }}>
        {message.text}
      </div>
      <button
        type="button"
        className="btn-close"
        style={{ fontSize: "0.9rem", marginLeft: "10px" }}
        onClick={onClose}
        aria-label="Close"
      ></button>
    </div>
  );
};

export default Toast;
