import React, { useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Home, Markets, Assets, Strategies } from "./components";
import "bootstrap";
import { Admin } from "./components/common/Interface/types";
import ChangePassword from "./components/ChangePassword";

type AppContextType = {
  setAdmin: (admin: Admin | null) => void;
  token: string | null;
  user?: Admin;
};

export const AppContext = React.createContext<AppContextType>({
  setAdmin: () => {},
  token: "",
});

const App = () => {
  const storedUser = JSON.parse(localStorage.getItem("authToken") || "{}");
  const [authToken, setAuthToken] = useState<string | null>(
    storedUser.token || null,
  );
  const [user, setUser] = useState<Admin | undefined>(
    storedUser.email ? storedUser : undefined,
  );

  const setAdmin = async (admin: Admin | null) => {
    if (admin === null) {
      localStorage.removeItem("authToken");
      setAuthToken(null);
      setUser(undefined);
    } else {
      const token = admin.access_token ?? "";
      const email = admin.email ?? "";
      localStorage.setItem("authToken", JSON.stringify({ email, token }));
      setAuthToken(token);
      setUser(admin);
    }
  };

  return (
    <BrowserRouter>
      <AppContext.Provider
        value={{
          setAdmin: setAdmin,
          token: authToken,
          user: user,
        }}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/resetPassword/:token" element={<ChangePassword />} />
          {authToken && (
            <>
              <Route path="/markets" element={<Markets />} />
              <Route path="/assets" element={<Assets />} />
              <Route path="/strategies" element={<Strategies />} />
            </>
          )}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </AppContext.Provider>
    </BrowserRouter>
  );
};

export default React.memo(App);
