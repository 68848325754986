import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { Header, Modal, Sidebar } from "../common";
import { StrategyColumn } from "../common/DataTable/column";
import Table from "../common/DataTable/table";
import { itemsPerPageOptions } from "../common/InputField/inputField";
import {
  AssetData,
  AssetsResponse,
  MarketData,
  MarketsResponse,
  StrategyData,
  validateStrategyForm,
  ValidationErrors,
} from "../common/Interface/types";
import { usePaginationReducer } from "../common/useReducer/usePaginationReducer";
import { getData, postData } from "../utils/api";
import { Form } from "./form";
import Toast from "../common/Toasts";

const StrategyList = () => {
  const { token, setAdmin } = useContext(AppContext);
  const [marketData, setMarketData] = useState<MarketData[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<MarketData | null>(null);
  const [selectedAsset, setSelectedAsset] = useState<AssetData | null>(null);
  const [assetData, setAssetData] = useState<AssetData[]>([]);
  const [formData, setFormData] = useState<StrategyData>({
    market_name: "",
    asset_name: "",
    name: "",
    initial_balance: "100000",
    recommended_stoploss: "",
    risk_profile: "medium",
    password: "",
  });
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const {
    state,
    setData,
    setPage,
    setItemsPerPage,
    setSort,
    setTotal,
    setTotalPages,
  } = usePaginationReducer();
  const [searchValue, setSearchValue] = useState<string>("");
  const [toastMessage, setToastMessage] = useState<{
    text: string;
    type: "success" | "error";
  } | null>(null);

  useEffect(() => {
    if (!localStorage.getItem("authToken")) setAdmin(null);
  }, [setAdmin]);

  const resetForm = () => {
    setFormData({
      market_name: "",
      asset_name: "",
      name: "",
      initial_balance: "100000",
      recommended_stoploss: "",
      risk_profile: "medium",
      password: "",
    });
    setSelectedMarket(null);
    setSelectedAsset(null);
    setErrors({});
    setShowErrors(false);
    setIsModalVisible(false);
  };
  const fetchStrategy = async () => {
    try {
      const query = new URLSearchParams({
        page: state.currentPage.toString(),
        limit: state.itemsPerPage.toString(),
        ...(searchValue && { search: searchValue.trim() }),
        ...(state.sortField && {
          sortColumn: state.sortField,
          sortOrder: state.sortDirection,
        }),
      }).toString();
      const response: any = await getData(`/admin/strategies?${query}`, token);
      const { data: strategies, pageInfo } = response.strategies;
      setData(strategies || []);
      setTotal(pageInfo.total);
      setTotalPages(Math.ceil(pageInfo.total / state.itemsPerPage));
    } catch (error: any) {
      setToastMessage({
        text: error?.data || error?.message,
        type: "error",
      });
    }
  };

  const handleMarketSelect = (market: MarketData) => {
    setSelectedMarket(market);
    const matchingAsset = assetData.find(
      (asset) => asset.market_id === market.id,
    );
    setFormData((prev) => ({
      ...prev,
      market_name: market.name,
      asset_name: matchingAsset ? matchingAsset.name : prev.asset_name,
    }));
    setSelectedAsset(matchingAsset || null);
  };
  const handleAssetSelect = (asset: AssetData) => {
    setSelectedAsset(asset);
    setFormData((prev) => ({ ...prev, asset_name: asset.name }));
  };
  const handleRiskProfileSelect = (riskProfile: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      risk_profile: riskProfile,
    }));
  };
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= state.totalPages) {
      setPage(page);
    }
  };

  const handleItemsPerPageChange = (limit: number) => {
    setItemsPerPage(limit);
    setPage(1);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setPage(1);
  };

  useEffect(() => {
    fetchStrategy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.currentPage,
    state.itemsPerPage,
    searchValue,
    state.sortField,
    state.sortDirection,
  ]);

  const handleClick = async () => {
    setIsModalVisible(true);
    if (token) {
      try {
        const [assetsData, marketsData] = await Promise.all([
          getData<AssetsResponse>("/admin/assets", token),
          getData<MarketsResponse>("/admin/markets", token),
        ]);
        setAssetData(assetsData?.assets?.data);
        setMarketData(marketsData?.markets?.data);
      } catch (error: any) {
        setToastMessage({
          text: error?.data || error?.message,
          type: "error",
        });
      }
    }
  };

  const filteredAssets = assetData.filter(
    (asset) => asset.market_id === selectedMarket?.id,
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validateStrategyForm(formData);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setShowErrors(true);
      return;
    }
    try {
      await postData("/admin/strategies", token, formData);
      resetForm();
      fetchStrategy();
      setToastMessage({
        text: "Strategy added succesfully",
        type: "success",
      });
    } catch (error: any) {
      setToastMessage({
        text: error?.data || error?.message,
        type: "error",
      });
    }
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    showErrors && setErrors({});
  };

  const handleClose = () => {
    resetForm();
  };
  const handleSortModelChange = (newModel: any) => {
    const currentSortField = state.sortField;
    const currentSortDirection = state.sortDirection;
    if (currentSortField === newModel) {
      if (currentSortDirection === "desc") {
        setSort(null, null);
      } else {
        setSort(newModel, "desc");
      }
    } else {
      setSort(newModel, "asc");
    }
  };

  const handleToastClose = () => {
    setToastMessage(null);
  };

  return (
    <>
      <Header />
      {toastMessage && (
        <Toast
          message={toastMessage}
          onClose={handleToastClose}
          setMessage={setToastMessage}
        />
      )}
      <main className="main">
        <Sidebar />
        <div className="app-main">
          <Table
            columns={StrategyColumn}
            data={state.data}
            itemsPerPageOptions={itemsPerPageOptions}
            itemsPerPage={state.itemsPerPage}
            onItemsPerPageChange={handleItemsPerPageChange}
            currentPage={state.currentPage}
            total={state.total}
            onPageChange={handlePageChange}
            name="Strategies"
            addIcon={true}
            handleClick={handleClick}
            handleSearch={handleSearch}
            searchValue={searchValue}
            handleSort={handleSortModelChange}
            sortField={state.sortField}
            sortDirection={state.sortDirection}
          />
        </div>
      </main>
      {isModalVisible && (
        <Modal title="Add Strategy" onClose={handleClose}>
          <div className="modal-body">
            <Form
              formData={formData}
              selectedMarket={selectedMarket}
              selectedAsset={selectedAsset}
              marketData={marketData}
              filteredAssets={filteredAssets}
              errors={errors}
              handleChange={handleChange}
              handleMarketSelect={handleMarketSelect}
              handleAssetSelect={handleAssetSelect}
              handleSubmit={handleSubmit}
              handleRiskProfileSelect={handleRiskProfileSelect}
            />
          </div>
        </Modal>
      )}
    </>
  );
};
export default React.memo(StrategyList);
