import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { UserColumn } from "../common/DataTable/column";
import Table from "../common/DataTable/table";
import { itemsPerPageOptions } from "../common/InputField/inputField";
import { getData } from "../utils/api";

import { Header, Sidebar } from "../common";
import { usePaginationReducer } from "../common/useReducer/usePaginationReducer";
import Toast from "../common/Toasts";

const Dashboard = () => {
  const { token, setAdmin } = useContext(AppContext);
  const [searchValue, setSearchValue] = useState<string>("");
  const {
    state,
    setData,
    setPage,
    setItemsPerPage,
    setSort,
    setTotal,
    setTotalPages,
  } = usePaginationReducer();
  const [toastMessage, setToastMessage] = useState<{
    text: string;
    type: "success" | "error";
  } | null>(null);

  useEffect(() => {
    if (!localStorage.getItem("authToken")) setAdmin(null);
  }, [setAdmin]);

  const fetchUser = async () => {
    try {
      const query = new URLSearchParams({
        page: state.currentPage.toString(),
        limit: state.itemsPerPage.toString(),
        ...(searchValue && { search: searchValue.trim() }),
        ...(state.sortField && {
          sortColumn: state.sortField,
          sortOrder: state.sortDirection,
        }),
      }).toString();
      const data: any = await getData(`/admin/users?${query}`, token);
      const { data: users, pageInfo } = data.users;
      setData(users || []);
      setTotal(pageInfo.total);
      setTotalPages(Math.ceil(pageInfo.total / state.itemsPerPage));
    } catch (error: any) {
      setToastMessage({
        text: error?.data || error?.message,
        type: "error",
      });
    }
  };

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= state.totalPages) {
      setPage(page);
    }
  };

  const handleItemsPerPageChange = (limit: number) => {
    setItemsPerPage(limit);
    setPage(1);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setPage(1);
  };

  const handleSortModelChange = (newModel: any) => {
    const currentSortField = state.sortField;
    const currentSortDirection = state.sortDirection;
    if (currentSortField === newModel) {
      if (currentSortDirection === "desc") {
        setSort(null, null);
      } else {
        setSort(newModel, "desc");
      }
    } else {
      setSort(newModel, "asc");
    }
  };

  const handleToastClose = () => {
    setToastMessage(null);
  };

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.currentPage,
    state.itemsPerPage,
    searchValue,
    state.sortField,
    state.sortDirection,
  ]);

  return (
    <>
      <Header />
      {toastMessage && (
        <Toast
          message={toastMessage}
          onClose={handleToastClose}
          setMessage={setToastMessage}
        />
      )}
      <main className="main">
        <Sidebar />
        <div className="app-main">
          <Table
            columns={UserColumn}
            data={state.data}
            itemsPerPageOptions={itemsPerPageOptions}
            itemsPerPage={state.itemsPerPage}
            onItemsPerPageChange={handleItemsPerPageChange}
            currentPage={state.currentPage}
            total={state.total}
            onPageChange={handlePageChange}
            handleSearch={handleSearch}
            searchValue={searchValue}
            name="Users"
            handleSort={handleSortModelChange}
            sortField={state.sortField}
            sortDirection={state.sortDirection}
          />
        </div>
      </main>
    </>
  );
};

export default React.memo(Dashboard);
