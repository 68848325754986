import { CloudUpload } from "react-bootstrap-icons";
import { useDropzone } from "react-dropzone";

export const Dropzone = ({
  setFileNames,
  setFormData,
  fileNames,
}: {
  setFileNames: any;
  setFormData: any;
  fileNames: string | null;
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles: Blob[]) => {
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = () => {
        const base64String = reader.result as string;
        setFormData((prevState: any) => ({
          ...prevState,
          image: base64String,
        }));
        setFileNames(base64String);
      };
      reader.onerror = (error) => {
        console.log("Error converting file to base64: ", error);
      };
    },
  });
  return (
    <div
      {...getRootProps()}
      className={`add-assets-header ${isDragActive ? "dragging" : ""}`}
      style={{
        position: "relative",
        padding: "32px",
        border: "1px dashed rgba(199, 215, 248, 0.4)",
        textAlign: "center",
        cursor: "pointer",
      }}
    >
      <input {...getInputProps()} className="hidden-input" />
      <div className="text-center">
        {/* <i className="i-upload m-auto d-block icon-xxxl"></i> */}
        <CloudUpload
          className="icon-xxl"
          style={{
            backgroundColor: "#6002EE",
            borderRadius: 50,
            padding: 10,
          }}
        />
      </div>
      <p className="text-center mb-0">
        Select or drag and drop your chosen market
      </p>
      {fileNames && (
        <div className="image-preview">
          <img src={fileNames} alt="Preview" height="100px" />
        </div>
      )}
    </div>
  );
};

export const convertImageUrlToBase64 = async (url: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          resolve(base64String);
        };
        reader.onerror = (error) => {
          reject(new Error("Error converting image to base64: " + error));
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => reject(new Error("Error fetching image: " + error)));
  });
};
