import React from "react";
import TimezoneSelect from "react-timezone-select";
import { MarketProps } from "../../Market/form";

const TimeZone: React.FC<MarketProps> = ({
  handleTimeZoneSelect,
  selectedTimeZone,
}) => {
  return (
    <div className="qt-input mb-3 ">
      <label className="form-label">Choose Time Zone</label>
      <TimezoneSelect
        value={selectedTimeZone}
        onChange={handleTimeZoneSelect}
        isSearchable={true}
        name="time_zone"
        placeholder="Select a Timezone"
      />
    </div>
  );
};

export default TimeZone;
