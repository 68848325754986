const createColumn = (
  id?: number,
  label?: string,
  accessor?: string,
  colSpan = 2,
  scope?: string | undefined,
) => ({
  id,
  label,
  accessor,
  colSpan,
  scope,
});

const columnsConfig = {
  asset: [
    createColumn(1, "", "image_url", 1),
    createColumn(2, "Market Name", "market.name", 3),
    createColumn(3, "Asset Name", "name", 2),
    createColumn(4, "Ticker Name", "ticker_name", 0, "col"),
    createColumn(5, "Action", "action", 0, "col"),
  ],
  strategy: [
    createColumn(1, "Strategy Name", "name", 2),
    createColumn(2, "Market Name", "market_name", 2),
    createColumn(3, "Asset Name", "asset_name", 2),
    createColumn(4, "Risk Profile", "risk_profile", 0, "col"),
    createColumn(5, "Action", "action", 0, "col"),
  ],
  user: [
    createColumn(1, "User", "name", 2),
    createColumn(2, "Email", "email", 2),
    createColumn(3, "Phone", "phone", 2),
    createColumn(4, "Risk appetite", "risk_profile", 2),
    createColumn(5, "Status", "status", 0, "col"),
    createColumn(6, "Action", "action", 0, "col"),
  ],
};

export const {
  asset: AssetColumn,
  strategy: StrategyColumn,
  user: UserColumn,
} = columnsConfig;
