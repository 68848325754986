import { useReducer } from "react";
const actionTypes = {
  SET_DATA: "SET_DATA",
  SET_PAGE: "SET_PAGE",
  SET_ITEMS_PER_PAGE: "SET_ITEMS_PER_PAGE",
  SET_SORT: "SET_SORT",
  SET_TOTAL: "SET_TOTAL",
  SET_TOTAL_PAGES: "SET_TOTAL_PAGES",
};

const initialState = {
  data: [],
  currentPage: 1,
  itemsPerPage: 10,
  sortField: "",
  sortDirection: "desc",
  total: 0,
  totalPages: 0,
};

function reducer(
  state: any,
  action: { type: any; payload: { sortField: any; sortDirection: any } }
) {
  switch (action.type) {
    case actionTypes.SET_DATA:
      return { ...state, data: action.payload };
    case actionTypes.SET_PAGE:
      return { ...state, currentPage: action.payload };
    case actionTypes.SET_ITEMS_PER_PAGE:
      return { ...state, itemsPerPage: action.payload };
    case actionTypes.SET_SORT:
      return {
        ...state,
        sortField: action.payload.sortField,
        sortDirection: action.payload.sortDirection,
      };
    case actionTypes.SET_TOTAL:
      return { ...state, total: action.payload };
    case actionTypes.SET_TOTAL_PAGES:
      return { ...state, totalPages: action.payload };
    default:
      throw new Error("Unknown action type");
  }
}

export function usePaginationReducer() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setData = (data: any) =>
    dispatch({ type: actionTypes.SET_DATA, payload: data });
  const setPage = (page: any) =>
    dispatch({ type: actionTypes.SET_PAGE, payload: page });
  const setItemsPerPage = (limit: any) =>
    dispatch({ type: actionTypes.SET_ITEMS_PER_PAGE, payload: limit });
  const setSort = (sortField: any, sortDirection: any) =>
    dispatch({
      type: actionTypes.SET_SORT,
      payload: { sortField, sortDirection },
    });
  const setTotal = (total: any) =>
    dispatch({ type: actionTypes.SET_TOTAL, payload: total });
  const setTotalPages = (pages: any) =>
    dispatch({ type: actionTypes.SET_TOTAL_PAGES, payload: pages });

  return {
    state,
    setData,
    setPage,
    setItemsPerPage,
    setSort,
    setTotal,
    setTotalPages,
  };
}
