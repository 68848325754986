import React from "react";
import { Column, DataItem, DataTableProps } from "../Interface/types";
import {
  ChevronCompactLeft,
  ChevronCompactRight,
  Pencil,
  PlusSquare,
  Trash,
} from "react-bootstrap-icons";

const Table: React.FC<DataTableProps> = ({
  columns,
  data,
  itemsPerPageOptions,
  itemsPerPage,
  onItemsPerPageChange,
  currentPage,
  total,
  onPageChange,
  name,
  addIcon,
  handleClick,
  handleEditClick,
  handleSearch,
  searchValue,
  handleSort,
  sortDirection,
  sortField,
}) => {
  const totalPages = Math.ceil(total / itemsPerPage);
  const getValue = (item: DataItem, accessor: string) => {
    return accessor
      .split(".")
      .reduce((acc: any, part) => acc && acc[part], item);
  };
  const maxVisiblePages = 3;

  const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }
  return (
    <div className="app-main-content d-flex flex-column h-100">
      <div className="app-content-card d-flex flex-column h-100">
        <div className="app-content-header">
          <h3 className="mb-0">{name}</h3>
          <div className="app-content-filter w-100">
            <div className="page-item-box">
              <div className="dropdown table-entries">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {itemsPerPage}
                </button>
                <ul className="dropdown-menu">
                  {itemsPerPageOptions.map((option) => (
                    <li key={option}>
                      <button
                        className="dropdown-item"
                        onClick={() => onItemsPerPageChange(option)}
                      >
                        {option}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <p className="mb-0">entries per page</p>
            </div>
            <div className="d-flex align-items-center ms-3 justify-content-end col-lg-6">
              <div className="search-box me-3">
                <div className="input-group search-bar">
                  <button
                    className="input-group-text icon btn dropdown"
                    id="basic-addon1"
                    aria-expanded="false"
                  >
                    <i className="i-search icon-md d-block"></i>
                  </button>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                    value={searchValue}
                    onChange={(e) => handleSearch?.(e.target.value)}
                  />
                </div>
              </div>

              {addIcon && (
                <div
                  className="qt-card-top d-flex align-items-center justify-content-center bg-transparent"
                  data-bs-placement="top"
                  onClick={handleClick}
                >
                  <PlusSquare className="icon-xl" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="app-card-body">
          <table className="table qt-table">
            <thead className="table-light">
              <tr>
                {columns.map((column: Column) => (
                  <th
                    key={column.id}
                    colSpan={column.colSpan || 2}
                    scope={column.scope || ""}
                    onClick={() =>
                      column.accessor !== "image_url" &&
                      column.accessor !== "action" &&
                      handleSort?.(column.accessor)
                    }
                    className="table-head"
                  >
                    {column.label}
                    {column.accessor !== "image_url" &&
                    column.accessor !== "action" ? (
                      sortField === column.accessor ? (
                        sortDirection === "asc" ? (
                          <i className="i-arrow-up-icon" />
                        ) : (
                          <i className="i-arrow-down-icon" />
                        )
                      ) : (
                        <i className="arrow-icons" />
                      )
                    ) : null}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.length > 0 ? (
                data.map((item: any, index) => (
                  <tr key={index}>
                    {columns.map((column: Column) => {
                      const value = getValue(item, column.accessor);
                      if (column.accessor === "image_url") {
                        return (
                          <td
                            key={column.id}
                            colSpan={column.colSpan || 2}
                            style={{ textAlign: "center" }}
                          >
                            <img
                              src={`${process.env.REACT_APP_API_URL}${value}?${new Date().getTime()}`}
                              alt="asset"
                              style={{
                                height: "36px",
                                objectFit: "cover",
                              }}
                            />
                          </td>
                        );
                      }

                      if (column.accessor === "market.name") {
                        return (
                          <td key={column.id} colSpan={column.colSpan || 2}>
                            {value}
                          </td>
                        );
                      }
                      return (
                        <td key={column.id} colSpan={column.colSpan || 1}>
                          {value}
                        </td>
                      );
                    })}
                    {columns.length > 0 && (
                      <td>
                        <div className="action-bar">
                          <button
                            className="btn bg-info"
                            onClick={() => handleEditClick?.(item.id)}
                          >
                            <Pencil />
                          </button>
                          {addIcon && (
                            <button className="btn bg-danger">
                              <Trash />
                            </button>
                          )}
                        </div>
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr style={{ border: "none" }}>
                  <td
                    colSpan={columns.length + 1}
                    className="no-record-container"
                  >
                    <div className="no-record-found">No records found</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {total > 0 && (
          <div className="app-card-footer">
            <p className="mb-0">
              Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
              {Math.min(currentPage * itemsPerPage, total)} of {total} entries
            </p>
            <nav aria-label="Page navigation">
              <ul className="pagination mb-0">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <ChevronCompactLeft size={20} title="Previous" />
                  </button>
                </li>
                {startPage > 1 && (
                  <>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => onPageChange(1)}
                      >
                        1
                      </button>
                    </li>
                    {startPage > 2 && (
                      <li className="page-item disabled">
                        <span className="page-link">...</span>
                      </li>
                    )}
                  </>
                )}
                {pages.map((page) => (
                  <li
                    key={page}
                    className={`page-item ${currentPage === page ? "active" : ""}`}
                  >
                    <button
                      className="page-link"
                      onClick={() => onPageChange(page)}
                    >
                      {page}
                    </button>
                  </li>
                ))}
                {endPage < totalPages && (
                  <>
                    {endPage < totalPages - 1 && (
                      <li className="page-item disabled">
                        <span className="page-link">...</span>
                      </li>
                    )}
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => onPageChange(totalPages)}
                      >
                        {totalPages}
                      </button>
                    </li>
                  </>
                )}

                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <ChevronCompactRight size={20} title="Next" />
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    </div>
  );
};

export default Table;
