import React from "react";
import { Dropdown, InputField } from "../common/InputField/inputField";
import { AssetData, FormProps, MarketData } from "../common/Interface/types";
import { Check, Floppy } from "react-bootstrap-icons";

export const Form: React.FC<FormProps> = ({
  formData,
  selectedMarket,
  marketData,
  errors,
  handleChange,
  handleMarketSelect,
  handleSubmit,
  filteredAssets = [],
  selectedAsset = null,
  handleAssetSelect = () => {},
  handleRiskProfileSelect = () => {},
}) => {
  const getMarketLabel = (market: MarketData) => market.name;
  const getAssetLabel = (asset: AssetData) => asset.name;
  return (
    <form onSubmit={handleSubmit}>
      <div className="add-market-content">
        <div className="bg-dark-card my-3 my-md-4 border-bottom-custome">
          <div className="add-market-card strategy-section">
            <div className="row g-3">
              <div className="col-lg-6">
                <Dropdown
                  data={marketData}
                  label="Choose Market"
                  selectedItem={selectedMarket}
                  handleSelect={handleMarketSelect}
                  getItemLabel={getMarketLabel}
                  error={errors.market_name}
                  placeholder="Select a Market"
                  message="No market available"
                />
              </div>
              <div className="col-lg-6">
                <Dropdown
                  label="Choose Asset"
                  data={filteredAssets}
                  selectedItem={selectedAsset}
                  handleSelect={handleAssetSelect}
                  getItemLabel={getAssetLabel}
                  error={errors.asset_name}
                  placeholder="Select an Asset"
                  message="No assets available"
                />
              </div>
              <div className="col-lg-6">
                <InputField
                  id="name"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  label="Strategy Name"
                  error={errors.name}
                />
              </div>
              <div className="col-lg-6">
                <InputField
                  id="password"
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  label="Password"
                  error={errors.password}
                />
              </div>
              <div className="col-lg-6">
                <InputField
                  id="recommended_stoploss"
                  type="number"
                  name="recommended_stoploss"
                  value={formData.recommended_stoploss}
                  onChange={handleChange}
                  label=" Recommended Stoploss"
                  error={errors.recommended_stoploss}
                />
              </div>
              <div className="col-lg-6">
                <InputField
                  id="initial_balance"
                  type="number"
                  name="initial_balance"
                  value={formData.initial_balance}
                  onChange={handleChange}
                  label=" Initial balance"
                  error={errors.initial_balance}
                />
              </div>
              <div className="col-lg-12">
                <div className="qt-input">
                  <label htmlFor="inputEmail4" className="form-label w-100">
                    Inverse
                  </label>
                  <div className="form-check form-check-inline">
                    <InputField
                      id="inlineRadio1"
                      name="inlineRadioOptions1"
                      value="option1"
                      type="radio"
                      radio={true}
                      label="True"
                    />
                  </div>
                  <div className="form-check form-check-inline">
                    <InputField
                      id="inlineRadio2"
                      name="inlineRadioOptions1"
                      value="option2"
                      type="radio"
                      radio={true}
                      label="False"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="qt-input">
                  <label htmlFor="inputEmail4" className="form-label w-100">
                    Pyramid
                  </label>
                  <div className="form-check form-check-inline">
                    <InputField
                      id="inlineRadio3"
                      name="inlineRadioOptions"
                      value="option1"
                      type="radio"
                      radio={true}
                      label="True"
                    />
                  </div>
                  <div className="form-check form-check-inline">
                    <InputField
                      id="inlineRadio4"
                      name="inlineRadioOptions"
                      value="option2"
                      type="radio"
                      radio={true}
                      label="False"
                    />
                  </div>
                </div>
              </div>
              <div className="w-100">
                <div className="border-line"></div>
              </div>
              <div className="col-lg-12">
                <div className="qt-input">
                  <label htmlFor="inputEmail4" className="form-label w-100">
                    Risk Profile
                  </label>
                  <div
                    className="btn-group qt-btn-group w-100"
                    role="group"
                    aria-label="Risk profile selector"
                  >
                    <button
                      type="button"
                      className={`btn btn-outline-primary ${
                        formData.risk_profile === "low" ? "active" : ""
                      }`}
                      onClick={() => handleRiskProfileSelect("low")}
                    >
                      <Check className="icon-md check-img" color="#FF7200" />
                      Low risk
                    </button>
                    <button
                      type="button"
                      className={`btn btn-outline-primary ${
                        formData.risk_profile === "medium" ? "active" : ""
                      }`}
                      onClick={() => handleRiskProfileSelect("medium")}
                    >
                      <Check className="icon-md check-img" color="#FF7200" />
                      Medium risk
                    </button>
                    <button
                      type="button"
                      className={`btn btn-outline-primary ${
                        formData.risk_profile === "high" ? "active" : ""
                      }`}
                      onClick={() => handleRiskProfileSelect("high")}
                    >
                      <Check className="icon-md check-img" color="#FF7200" />
                      High risk
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <InputField
                  id="max_drawdown"
                  type="number"
                  defaultValue="0"
                  label=" Max Drawdown"
                />
              </div>
              <div className="col-lg-6">
                <InputField
                  id="win_rate"
                  type="number"
                  defaultValue="0"
                  label="Win Rate"
                />
              </div>
              <div className="col-lg-6">
                <InputField
                  id="time_frame"
                  type="number"
                  defaultValue="0"
                  label=" Time Frame"
                />
              </div>
              <div className="col-lg-6">
                <InputField
                  id="profit_factor"
                  type="number"
                  defaultValue="0"
                  label="Profit Factor"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="btn-bar">
          <button
            type="submit"
            className="btn btn-gray me-auto d-flex"
            data-bs-dismiss="modal"
          >
            <Floppy className="me-2" />
            Save
          </button>
        </div>
      </div>
    </form>
  );
};
