import React, { ChangeEvent } from "react";
import { Floppy } from "react-bootstrap-icons";
import CurrencyDisplay from "../common/CurrencyList";
import TimeZone from "../common/Timezone";
import { MarketData, ValidationErrors } from "../common/Interface/types";
import { InputField } from "../common/InputField/inputField";

export interface MarketProps {
  handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  formData?: MarketData;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  dismissValue?: string;
  error?: ValidationErrors;
  selectedTimeZone?: any;
  handleTimeZoneSelect?: any;
  currencyData?: {
    value: string;
    label: string;
    symbol: string;
  }[];
  handleCurrencySelect?: any;
  getCurrencyLabel?: any;
  selectedCurrency?: MarketData;
}

export const Form: React.FC<MarketProps> = ({
  handleSubmit,
  formData,
  handleChange,
  dismissValue,
  error,
  selectedTimeZone,
  handleTimeZoneSelect,
  currencyData,
  handleCurrencySelect,
  getCurrencyLabel,
  selectedCurrency,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body pt-2 pb-2 overflow-visible">
        <InputField
          id="name"
          type="text"
          name="name"
          value={formData?.name}
          onChange={handleChange}
          label="Market Name"
          error={error?.name}
        />
        <TimeZone
          handleTimeZoneSelect={handleTimeZoneSelect}
          selectedTimeZone={selectedTimeZone}
          error={error}
        />
        <CurrencyDisplay
          currencyData={currencyData}
          handleCurrencySelect={handleCurrencySelect}
          getCurrencyLabel={getCurrencyLabel}
          selectedCurrency={selectedCurrency}
          error={error}
        />
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          className="btn btn-gray me-auto d-flex"
          data-bs-dismiss={dismissValue}
        >
          <Floppy className="me-2" />
          Save
        </button>
      </div>
    </form>
  );
};
