import axios, { AxiosResponse } from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const createApiClient = (token: string | null) => {
  const headers: { [key: string]: string } = {};
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  return axios.create({
    baseURL: API_URL,
    headers: headers,
  });
};

interface ApiError {
  message: string;
  status?: number;
  data?: any;
}
// Create a generic type for API response
type ApiResponse<T> = AxiosResponse<T>;

export const handleError = (error: any): ApiError => {
  let apiError: ApiError = {
    message: "An unexpected error occurred",
  };

  if (error.isAxiosError) {
    apiError = {
      message: error.message,
      status: error.response?.status,
      data: error.response?.data?.error,
    };
  } else {
    apiError.message = error.message || apiError.message;
  }

  return apiError;
};

export const getData = async <T>(
  endpoint: string,
  token: string | null,
): Promise<T> => {
  const apiClient = createApiClient(token);
  try {
    const response: ApiResponse<T> = await apiClient.get(endpoint);
    return response.data;
  } catch (error) {
    const apiError = handleError(error);
    throw apiError;
  }
};
export const postData = async <T>(
  endpoint: string,
  token: string | null,
  formData: Record<any, any>,
): Promise<T> => {
  const apiClient = createApiClient(token);
  try {
    const response: ApiResponse<T> = await apiClient.post(endpoint, formData);
    return response.data;
  } catch (error) {
    const apiError = handleError(error);
    throw apiError;
  }
};
export const putData = async <T>(
  endpoint: string,
  token: string | null,
  formData: Record<any, any>,
): Promise<T> => {
  const apiClient = createApiClient(token);
  try {
    const response: ApiResponse<T> = await apiClient.put(endpoint, formData);
    return response.data;
  } catch (error) {
    const apiError = handleError(error);
    throw apiError;
  }
};
