import React, { ChangeEvent, useContext, useState } from "react";
import axios from "axios";
import { AppContext } from "../App";
import logoImg from "../assets/img/logo.svg";
import successImg from "../assets/img/success.gif";
import { InputField } from "./common/InputField/inputField";
import { validateSignInForm, ValidationErrors } from "./common/Interface/types";
import Toast from "./common/Toasts";

const SignIn = () => {
  const { setAdmin } = useContext(AppContext);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<{
    text: string;
    type: "success" | "error";
  } | null>(null);

  const handleLoginApi = async () => {
    try {
      const loginData = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/login`,
        {
          email: formData?.email,
          password: formData?.password,
        },
      );
      setLoggedIn(true);
      setTimeout(() => {
        setAdmin(loginData?.data?.admin);
      }, 2000);
    } catch (error: any) {
      setToastMessage({
        text: error?.data || error?.message,
        type: "error",
      });
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    showErrors && setErrors({});
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validateSignInForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setShowErrors(true);
      return;
    }
    handleLoginApi();
  };

  const handleToastClose = () => {
    setToastMessage(null);
  };

  const welcome = () => {
    return (
      <main className="main login-section qt-success-section">
        <div className="bg-dark-card d-flex border-0 qt-card">
          <div>
            <img className="animated-gif" src={successImg} alt="welcome" />
            <h1>Welcome to Quant11</h1>
          </div>
        </div>
      </main>
    );
  };

  const login = () => {
    return (
      <>
        {toastMessage && (
          <Toast
            message={toastMessage}
            onClose={handleToastClose}
            setMessage={setToastMessage}
          />
        )}
        <main className="main login-section after-circle">
          <div className="bg-dark-card d-flex border-0">
            <div className="qt-card w-100">
              <h2>Log In</h2>
              <form className="login-form qt-11-form" onSubmit={handleSubmit}>
                <InputField
                  id="email"
                  type="email"
                  name="email"
                  placeholder="john@email.com"
                  value={formData?.email}
                  onChange={handleChange}
                  label="Email"
                  error={errors?.email}
                />
                <InputField
                  id="password"
                  type="password"
                  name="password"
                  placeholder="******"
                  value={formData?.password}
                  onChange={handleChange}
                  label="Password"
                  error={errors?.password}
                />
                <div className="col text-end">
                  <a href="/#">Forgot password?</a>
                </div>
                <button type="submit" className="btn btn-primary w-100">
                  Continue
                </button>
              </form>
            </div>
          </div>
        </main>
      </>
    );
  };
  return (
    <>
      <div className="login-header w-100">
        <img src={logoImg} alt="logo" title="Logo" />
      </div>
      {loggedIn ? welcome() : login()}
    </>
  );
};
export default React.memo(SignIn);
