import Select from "react-select";
import { MarketProps } from "../../Market/form";
const CurrencyDisplay = ({
  currencyData,
  handleCurrencySelect,
  getCurrencyLabel,
  selectedCurrency,
}: MarketProps) => {
  const selectedOption = currencyData?.find(
    (option: { symbol: any }) => option.symbol === selectedCurrency,
  );

  return (
    <div className="qt-input">
      <label htmlFor="inputState" className="form-label">
        Choose Currency
      </label>
      <Select
        getOptionLabel={getCurrencyLabel}
        value={selectedOption || selectedCurrency}
        options={currencyData}
        placeholder="Select a Currency"
        onChange={handleCurrencySelect}
      />
    </div>
  );
};

export default CurrencyDisplay;
