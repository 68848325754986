import { ChangeEvent } from "react";

export interface Admin {
  id: string;
  email: string;
  access_token: string;
  expiry: number;
}

export interface ChangePasswordData {
  password: string;
  confirm_password: string;
}

interface SignInData {
  email: string;
  password: string;
}

export interface AssetData {
  id?: number;
  name: string;
  market?: MarketData;
  ticker_name: string;
  image?: string;
  market_id: string | any;
  risk_profile?: string;
  recommended_stoploss?: string;
  initial_balance?: string;
  password?: string;
  image_url?: string;
}

export interface MarketData {
  id?: string;
  name: string;
  currency_code?: string;
  currency_symbol?: string;
  time_zone: string;
}

export interface StrategyData {
  id?: number;
  name: string;
  market_name: string;
  asset_name: string;
  risk_profile: string;
  initial_balance: string;
  recommended_stoploss: string;
  password: string;
  ticker_name?: string;
}

export interface ValidationErrors {
  market_id?: string;
  name?: string;
  ticker_name?: string;
  market_name?: string;
  asset_name?: string;
  initial_balance?: string;
  recommended_stoploss?: string;
  risk_profile?: string;
  password?: string;
  email?: string;
  change_password?: {
    password?: string;
    confirm_password?: string;
  };
  currency_code?: string;
  currency_symbol?: string;
  time_zone?: string;
}
export interface FormData {
  market_id?: string;
  name: string;
  ticker_name?: string;
  image?: string;
  market_name?: string;
  asset_name?: string;
  initial_balance?: string;
  risk_profile?: string;
  password?: string;
}

export interface FormProps {
  formData: AssetData | StrategyData;
  selectedMarket: MarketData | null;
  selectedAsset?: AssetData | null;
  filteredAssets?: AssetData[];
  marketData: MarketData[];
  errors: ValidationErrors;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleMarketSelect: (market: MarketData) => void;
  handleAssetSelect?: (asset: AssetData) => void;
  setFileNames?: any;
  setFormData?: any;
  fileNames?: any;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleRiskProfileSelect?: (riskProfile: string) => void;
}

export interface Column {
  id: number;
  label: string;
  accessor?: any;
  colSpan?: number;
  scope?: string;
}
export interface DataItem {
  id?: number;
  name?: string;
  email?: string;
  phone?: number;
  risk_profile?: string;
}

export interface DataTableProps {
  columns: Column[] | any;
  data: DataItem[];
  itemsPerPageOptions: number[];
  itemsPerPage: number;
  onItemsPerPageChange: (newItemsPerPage: number) => void;
  currentPage: number;
  total: number;
  onPageChange: (newPage: number) => void;
  name: string;
  addIcon?: boolean;
  handleClick?: () => void;
  handleEditClick?: (id: number) => void;
  searchValue?: string;
  handleSearch?: (query: string) => void;
  handleSort?: (sort: string) => void;
  sortField?: string;
  sortDirection?: string;
}

export interface Pagination {
  currentPage: number;
  total: number;
  itemsPerPage: number;
  totalPages: number;
  sortField: string;
  sortDirection: "asc" | "desc";
}

export const validateChangePasswordForm = (
  data: ChangePasswordData,
): ValidationErrors => {
  const validationErrors: ValidationErrors = {};
  validationErrors.change_password = {};
  if (data.password.trim() === "")
    validationErrors.change_password.password = "Password is required";
  if (data.confirm_password.trim() === "")
    validationErrors.change_password.confirm_password =
      "Confirm Password is required";
  if (data.password.trim() !== data.confirm_password.trim())
    validationErrors.change_password.confirm_password = "Password mis-matched";
  return validationErrors;
};

export const validateAssetForm = (data: AssetData): ValidationErrors => {
  const validationErrors: ValidationErrors = {};
  if (data.market_id.trim() === "")
    validationErrors.market_id = "Market name is required";
  if (data.name.trim() === "") validationErrors.name = "Asset name is required";
  if (data.ticker_name.trim() === "")
    validationErrors.ticker_name = "Ticker name is required";
  return validationErrors;
};

export const validateSignInForm = (data: SignInData): ValidationErrors => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
  const validationErrors: ValidationErrors = {};
  if (data.email.trim() === "") {
    validationErrors.email = "email is required";
  } else if (!emailRegex.test(data.email.trim())) {
    validationErrors.email = "email is invalid";
  }
  if (data.password.trim() === "")
    validationErrors.password = "password is required";
  return validationErrors;
};

export const validateStrategyForm = (data: StrategyData): ValidationErrors => {
  const validationErrors: ValidationErrors = {};
  if (data.market_name.trim() === "")
    validationErrors.market_name = "Market name is required";
  if (data.name.trim() === "")
    validationErrors.name = "Strategy name is required";
  if (data.password.trim() === "")
    validationErrors.password = "Password is required";
  if (data.initial_balance.trim() === "")
    validationErrors.initial_balance = "Initial balance is required";
  return validationErrors;
};

export const validateMarketForm = (data: MarketData): ValidationErrors => {
  const validationErrors: ValidationErrors = {};
  if (data.name.trim() === "")
    validationErrors.name = "Market name is required";
  if (data?.currency_code?.trim() === "")
    validationErrors.currency_code = "currency is required";
  if (data.time_zone.trim() === "")
    validationErrors.time_zone = "Time Zone is required";
  return validationErrors;
};

export interface AssetsResponse {
  assets: {
    data: AssetData[];
    pageInfo: Pagination;
  };
}

export interface MarketsResponse {
  markets: {
    data: MarketData[];
    pageInfo: Pagination;
  };
}
